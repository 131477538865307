const Permission = {
    // DASHBOARD Permissions

    CREATE_DASHBOARD: "CREATE_DASHBOARD",
    READ_ALL_DASHBOARDS: "READ_ALL_DASHBOARDS",
    UPDATE_DASHBOARD: "UPDATE_DASHBOARD",
    DELETE_DASHBOARD: "DELETE_DASHBOARD",
    READ_SINGLE_DASHBOARD: "READ_SINGLE_DASHBOARD",

    // ADMIN_USER Permissions

    CREATE_ADMIN_USER: "CREATE_ADMIN_USER",
    READ_ALL_ADMIN_USERS: "READ_ALL_ADMIN_USERS",
    UPDATE_ADMIN_USER: "UPDATE_ADMIN_USER",
    DELETE_ADMIN_USER: "DELETE_ADMIN_USER",
    READ_SINGLE_ADMIN_USER: "READ_SINGLE_ADMIN_USER",

    // ROLE Permissions

    CREATE_ROLE: "CREATE_ROLE",
    READ_ALL_ROLES: "READ_ALL_ROLES",
    UPDATE_ROLE: "UPDATE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    READ_SINGLE_ROLE: "READ_SINGLE_ROLE",

    // USER Permissions

    CREATE_USER: "CREATE_USER",
    READ_ALL_USERS: "READ_ALL_USERS",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER",
    READ_SINGLE_USER: "READ_SINGLE_USER",

    // COMPANY Permissions

    CREATE_COMPANY: "CREATE_COMPANY",
    READ_ALL_COMPANYS: "READ_ALL_COMPANYS",
    UPDATE_COMPANY: "UPDATE_COMPANY",
    DELETE_COMPANY: "DELETE_COMPANY",
    READ_SINGLE_COMPANY: "READ_SINGLE_COMPANY",

    // STATIC_PAGE Permissions

    CREATE_STATIC_PAGE: "CREATE_STATIC_PAGE",
    READ_ALL_STATIC_PAGES: "READ_ALL_STATIC_PAGES",
    UPDATE_STATIC_PAGE: "UPDATE_STATIC_PAGE",
    DELETE_STATIC_PAGE: "DELETE_STATIC_PAGE",
    READ_SINGLE_STATIC_PAGE: "READ_SINGLE_STATIC_PAGE",

    // CUSTOMER_FEEDBACK Permissions

    CREATE_CUSTOMER_FEEDBACK: "CREATE_CUSTOMER_FEEDBACK",
    READ_ALL_CUSTOMER_FEEDBACKS: "READ_ALL_CUSTOMER_FEEDBACKS",
    UPDATE_CUSTOMER_FEEDBACK: "UPDATE_CUSTOMER_FEEDBACK",
    DELETE_CUSTOMER_FEEDBACK: "DELETE_CUSTOMER_FEEDBACK",
    READ_SINGLE_CUSTOMER_FEEDBACK: "READ_SINGLE_CUSTOMER_FEEDBACK",

    // CONTACT_MESSAGE Permissions

    CREATE_CONTACT_MESSAGE: "CREATE_CONTACT_MESSAGE",
    READ_ALL_CONTACT_MESSAGES: "READ_ALL_CONTACT_MESSAGES",
    UPDATE_CONTACT_MESSAGE: "UPDATE_CONTACT_MESSAGE",
    DELETE_CONTACT_MESSAGE: "DELETE_CONTACT_MESSAGE",
    READ_SINGLE_CONTACT_MESSAGE: "READ_SINGLE_CONTACT_MESSAGE",

    // SITE_CONFIG Permissions

    CREATE_SITE_CONFIG: "CREATE_SITE_CONFIG",
    READ_ALL_SITE_CONFIGS: "READ_ALL_SITE_CONFIGS",
    UPDATE_SITE_CONFIG: "UPDATE_SITE_CONFIG",
    DELETE_SITE_CONFIG: "DELETE_SITE_CONFIG",
    READ_SINGLE_SITE_CONFIG: "READ_SINGLE_SITE_CONFIG",

    // WHY_CHOOSE_US Permissions

    CREATE_WHY_CHOOSE_US: "CREATE_WHY_CHOOSE_US",
    READ_ALL_WHY_CHOOSE_USS: "READ_ALL_WHY_CHOOSE_USS",
    UPDATE_WHY_CHOOSE_US: "UPDATE_WHY_CHOOSE_US",
    DELETE_WHY_CHOOSE_US: "DELETE_WHY_CHOOSE_US",
    READ_SINGLE_WHY_CHOOSE_US: "READ_SINGLE_WHY_CHOOSE_US",

    // FAQ Permissions

    CREATE_FAQ: "CREATE_FAQ",
    READ_ALL_FAQS: "READ_ALL_FAQS",
    UPDATE_FAQ: "UPDATE_FAQ",
    DELETE_FAQ: "DELETE_FAQ",
    READ_SINGLE_FAQ: "READ_SINGLE_FAQ",

    // CATEGORY Permissions

    CREATE_CATEGORY: "CREATE_CATEGORY",
    READ_ALL_CATEGORYS: "READ_ALL_CATEGORYS",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",
    DELETE_CATEGORY: "DELETE_CATEGORY",
    READ_SINGLE_CATEGORY: "READ_SINGLE_CATEGORY",

    // DEPARTMENT Permissions

    CREATE_DEPARTMENT: "CREATE_DEPARTMENT",
    READ_ALL_DEPARTMENTS: "READ_ALL_DEPARTMENTS",
    UPDATE_DEPARTMENT: "UPDATE_DEPARTMENT",
    DELETE_DEPARTMENT: "DELETE_DEPARTMENT",
    READ_SINGLE_DEPARTMENT: "READ_SINGLE_DEPARTMENT",

    // PROJECTS Permissions

    CREATE_PROJECTS: "CREATE_PROJECTS",
    READ_ALL_PROJECTSS: "READ_ALL_PROJECTSS",
    UPDATE_PROJECTS: "UPDATE_PROJECTS",
    DELETE_PROJECTS: "DELETE_PROJECTS",
    READ_SINGLE_PROJECTS: "READ_SINGLE_PROJECTS",

    // PROJECT_WINNING_DECLARATION Permissions

    CREATE_PROJECT_WINNING_DECLARATION: "CREATE_PROJECT_WINNING_DECLARATION",
    READ_ALL_PROJECT_WINNING_DECLARATIONS:
        "READ_ALL_PROJECT_WINNING_DECLARATIONS",
    UPDATE_PROJECT_WINNING_DECLARATION: "UPDATE_PROJECT_WINNING_DECLARATION",
    DELETE_PROJECT_WINNING_DECLARATION: "DELETE_PROJECT_WINNING_DECLARATION",
    READ_SINGLE_PROJECT_WINNING_DECLARATION:
        "READ_SINGLE_PROJECT_WINNING_DECLARATION",

    // BILLINGS Permissions

    CREATE_BILLINGS: "CREATE_BILLINGS",
    READ_ALL_BILLINGSS: "READ_ALL_BILLINGSS",
    UPDATE_BILLINGS: "UPDATE_BILLINGS",
    DELETE_BILLINGS: "DELETE_BILLINGS",
    READ_SINGLE_BILLINGS: "READ_SINGLE_BILLINGS",

    // COMPANY_NOTIFICATIONS Permissions

    CREATE_COMPANY_NOTIFICATIONS: "CREATE_COMPANY_NOTIFICATIONS",
    READ_ALL_COMPANY_NOTIFICATIONSS: "READ_ALL_COMPANY_NOTIFICATIONSS",
    UPDATE_COMPANY_NOTIFICATIONS: "UPDATE_COMPANY_NOTIFICATIONS",
    DELETE_COMPANY_NOTIFICATIONS: "DELETE_COMPANY_NOTIFICATIONS",
    READ_SINGLE_COMPANY_NOTIFICATIONS: "READ_SINGLE_COMPANY_NOTIFICATIONS",

    // CHAT Permissions

    CREATE_CHAT: "CREATE_CHAT",
    READ_ALL_CHATS: "READ_ALL_CHATS",
    UPDATE_CHAT: "UPDATE_CHAT",
    DELETE_CHAT: "DELETE_CHAT",
    READ_SINGLE_CHAT: "READ_SINGLE_CHAT",

    // REQUESTED_CATEGORY Permissions

    CREATE_REQUESTED_CATEGORY: "CREATE_REQUESTED_CATEGORY",
    READ_ALL_REQUESTED_CATEGORYS: "READ_ALL_REQUESTED_CATEGORYS",
    UPDATE_REQUESTED_CATEGORY: "UPDATE_REQUESTED_CATEGORY",
    DELETE_REQUESTED_CATEGORY: "DELETE_REQUESTED_CATEGORY",
    READ_SINGLE_REQUESTED_CATEGORY: "READ_SINGLE_REQUESTED_CATEGORY",

    // PROJECT_APPLICATIONS Permissions

    CREATE_PROJECT_APPLICATIONS: "CREATE_PROJECT_APPLICATIONS",
    READ_ALL_PROJECT_APPLICATIONSS: "READ_ALL_PROJECT_APPLICATIONSS",
    UPDATE_PROJECT_APPLICATIONS: "UPDATE_PROJECT_APPLICATIONS",
    DELETE_PROJECT_APPLICATIONS: "DELETE_PROJECT_APPLICATIONS",
    READ_SINGLE_PROJECT_APPLICATIONS: "READ_SINGLE_PROJECT_APPLICATIONS",

    // PROFILE_DOWNLOAD_REQUEST Permissions

    CREATE_PROFILE_DOWNLOAD_REQUEST: "CREATE_PROFILE_DOWNLOAD_REQUEST",
    READ_ALL_PROFILE_DOWNLOAD_REQUESTS: "READ_ALL_PROFILE_DOWNLOAD_REQUESTS",
    UPDATE_PROFILE_DOWNLOAD_REQUEST: "UPDATE_PROFILE_DOWNLOAD_REQUEST",
    DELETE_PROFILE_DOWNLOAD_REQUEST: "DELETE_PROFILE_DOWNLOAD_REQUEST",
    READ_SINGLE_PROFILE_DOWNLOAD_REQUEST:
        "READ_SINGLE_PROFILE_DOWNLOAD_REQUEST",

    // PROFESSIONAL_CATEGORY Permissions

    CREATE_PROFESSIONAL_CATEGORY: "CREATE_PROFESSIONAL_CATEGORY",
    READ_ALL_PROFESSIONAL_CATEGORYS: "READ_ALL_PROFESSIONAL_CATEGORYS",
    UPDATE_PROFESSIONAL_CATEGORY: "UPDATE_PROFESSIONAL_CATEGORY",
    DELETE_PROFESSIONAL_CATEGORY: "DELETE_PROFESSIONAL_CATEGORY",
    READ_SINGLE_PROFESSIONAL_CATEGORY: "READ_SINGLE_PROFESSIONAL_CATEGORY",

    // CONSULTANT_CATEGORY Permissions

    CREATE_CONSULTANT_CATEGORY: "CREATE_CONSULTANT_CATEGORY",
    READ_ALL_CONSULTANT_CATEGORYS: "READ_ALL_CONSULTANT_CATEGORYS",
    UPDATE_CONSULTANT_CATEGORY: "UPDATE_CONSULTANT_CATEGORY",
    DELETE_CONSULTANT_CATEGORY: "DELETE_CONSULTANT_CATEGORY",
    READ_SINGLE_CONSULTANT_CATEGORY: "READ_SINGLE_CONSULTANT_CATEGORY",

    // MOU Permissions

    CREATE_MOU: "CREATE_MOU",
    READ_ALL_MOUS: "READ_ALL_MOUS",
    UPDATE_MOU: "UPDATE_MOU",
    DELETE_MOU: "DELETE_MOU",
    READ_SINGLE_MOU: "READ_SINGLE_MOU",

    // ASSIGNED_MOU Permissions

    CREATE_ASSIGNED_MOU: "CREATE_ASSIGNED_MOU",
    READ_ALL_ASSIGNED_MOUS: "READ_ALL_ASSIGNED_MOUS",
    UPDATE_ASSIGNED_MOU: "UPDATE_ASSIGNED_MOU",
    DELETE_ASSIGNED_MOU: "DELETE_ASSIGNED_MOU",
    READ_SINGLE_ASSIGNED_MOU: "READ_SINGLE_ASSIGNED_MOU",

    // PROFILE_SHARING Permissions

    CREATE_PROFILE_SHARING: "CREATE_PROFILE_SHARING",
    READ_ALL_PROFILE_SHARINGS: "READ_ALL_PROFILE_SHARINGS",
    UPDATE_PROFILE_SHARING: "UPDATE_PROFILE_SHARING",
    DELETE_PROFILE_SHARING: "DELETE_PROFILE_SHARING",
    READ_SINGLE_PROFILE_SHARING: "READ_SINGLE_PROFILE_SHARING",

    // GLOBAL_NOTIFICATION Permissions

    CREATE_GLOBAL_NOTIFICATION: "CREATE_GLOBAL_NOTIFICATION",
    READ_ALL_GLOBAL_NOTIFICATIONS: "READ_ALL_GLOBAL_NOTIFICATIONS",
    UPDATE_GLOBAL_NOTIFICATION: "UPDATE_GLOBAL_NOTIFICATION",
    DELETE_GLOBAL_NOTIFICATION: "DELETE_GLOBAL_NOTIFICATION",
    READ_SINGLE_GLOBAL_NOTIFICATION: "READ_SINGLE_GLOBAL_NOTIFICATION",

    // APP_LANGUAGE Permissions

    CREATE_APP_LANGUAGE: "CREATE_APP_LANGUAGE",
    READ_ALL_APP_LANGUAGES: "READ_ALL_APP_LANGUAGES",
    UPDATE_APP_LANGUAGE: "UPDATE_APP_LANGUAGE",
    DELETE_APP_LANGUAGE: "DELETE_APP_LANGUAGE",
    READ_SINGLE_APP_LANGUAGE: "READ_SINGLE_APP_LANGUAGE",

    // BlOG_PERMISSIONS

    CREATE_BLOG: "CREATE_BLOG",
    READ_ALL_BLOG: "READ_ALL_BLOG",
    UPDATE_BLOG: "UPDATE_BLOG",
    DELETE_BLOG: "DELETE_BLOG",
    READ_SINGLE_BLOG: "READ_SINGLE_BLOG",
    // VIDEO_PERMISSIONS

    CREATE_VIDEO: "CREATE_VIDEO",
    READ_ALL_VIDEO: "READ_ALL_VIDEO",
    UPDATE_VIDEO: "UPDATE_VIDEO",
    DELETE_VIDEO: "DELETE_VIDEO",
    READ_SINGLE_VIDEO: "READ_SINGLE_VIDEO",

    // OUTER_NOTIFICATION Permissions

    CREATE_OUTER_NOTIFICATION: "CREATE_OUTER_NOTIFICATION",
    READ_ALL_OUTER_NOTIFICATIONS: "READ_ALL_OUTER_NOTIFICATIONS",
    UPDATE_OUTER_NOTIFICATION: "UPDATE_OUTER_NOTIFICATION",
    DELETE_OUTER_NOTIFICATION: "DELETE_OUTER_NOTIFICATION",
    READ_SINGLE_OUTER_NOTIFICATION: "READ_SINGLE_OUTER_NOTIFICATION",
    // Chats
    CAN_CHAT: "CAN_CHAT",
    // OTHERS PERMISSIONS
    LOGIN_INTO_USER_ACCOUNT: "LOGIN_INTO_USER_ACCOUNT"
};
export default Permission;
